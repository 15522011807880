<template>
  <a-card hoverable>
    <div v-if="special === 1" class="clearfix">
      <a-upload action="image/*" list-type="picture-card" v-model:file-list="imageList" @preview="handlePreview" :multiple="true" :before-upload="beforeUpload">
        <div v-if="imageList.length < 8">
          <plus-outlined />
          <div class="ant-upload-text">上传公司要求图片</div>
        </div>
      </a-upload>
      <a-modal :visible="preview" :footer="null" @cancel="handleCancel">
        <img style="width: 100%" :src="previewImage" />
      </a-modal>
      <a-button v-show="imageList.length > 0 && !handleChangeStatus" type="primary" @click="handleChange()">开始上传</a-button>
    </div>

    <div v-else>
      <div v-if="imageList.length > 0 && !handleChangeStatus">
        <a-button type="primary" @click="handleChange()">请点击批量保存</a-button>
      </div>
      <a-upload-dragger class="avatar-uploader upload-list-inline" list-type="picture" v-model:fileList="imageList" accept="image/*" :multiple="true" :before-upload="beforeUpload">
        <div>
          <plus-outlined></plus-outlined>
          <div class="ant-upload-text">上传公司要求图片</div>
        </div>
      </a-upload-dragger>

    </div>
    <a-modal v-model:visible="uploadShow" title="图片上传进度" @ok="onUploadModal">
      <p>总共上传: {{fileImgTotal}}张</p>
      <p>正在上传: {{fileImgCodeLoading}}张</p>
      <p>成功上传: {{fileImgCodeSuccess}}张</p>
      <p class="d">
        <view class="col-b">上传失败: {{fileImgCodeError}}张
        </view>
        <a-button :loading="fileImgCodeLoading===0?false:true" v-show="fileImgCodeError===0?false:true" type="primary" @click="submitError" danger>重新上传</a-button>
      </p>
    </a-modal>
  </a-card>
</template>
<script>
import { PlusOutlined /* EditOutlined */ } from '@ant-design/icons-vue'
import { reactive, ref, toRefs } from 'vue'
import { uploadVehicle as imageUpload, inspectUpload } from '@/api/global'
import { message } from 'ant-design-vue'

function getBase64 (img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

export default {
  name: 'ImageUpload',
  props: {
    info: {
      type: String
    },
    special: String
  },
  emits: [
    'change', 'uploadPic', 'inspectionLoadData'
  ],
  setup (props, { emit }) {
    console.log(props)
    const imageRef = ref()
    const data = reactive({
      handleChangeStatus: false,
      uploadShow: false,
      fileImgTotal: 0,
      fileImgCodeLoading: 0,
      fileImgCodeSuccess: 0,
      fileImgCodeError: 0,
      fileImgCodeErrorArry: [],
      special: props.special,
      imageList: [],
      preview: false,
      fileUrl: '',
      previewImage: '',
      form: {
        id: '',
        fileId: '',
        fileUrl: null,
        orderVehicleId: props.info,
        inspectType: {
          label: '车伤',
          value: 1
        },
        fileRemark: ''
      },
      editable: false
    })

    const handlePreview = async file => {
      if (!file.url && !file.preview) {
        await getBase64(file.originFileObj, base64Url => {
          data.previewImage = base64Url
        })
      }
      data.preview = true
    }
    const beforeUpload = file => {
      data.imageList = [file]
      return false
    }

    const handleChange = () => {
      // const status = info.file.status
      const len = data.imageList.length
      data.fileImgTotal = len
      data.fileImgCodeSuccess = 0
      data.fileImgCodeLoading = data.fileImgTotal - data.fileImgCodeSuccess
      data.fileImgCodeErrorArry = []
      data.uploadShow = true
      console.log('data.uploadShow', len, data.imageList)
      data.handleChangeStatus = true
      updateBatch(0, len, data.imageList)
    }
    const submitError = () => {
      data.fileImgCodeLoading = data.fileImgCodeError
      data.fileImgCodeError = 0
      const list = []
      data.fileImgCodeErrorArry.forEach(e => {
        list.push(data.imageList[e])
      })
      data.uploadShow = true
      data.imageList = list
      data.fileImgCodeErrorArry = []
      updateBatch(0, list.length, list)
    }
    const updateBatch = (index, len, imageList) => {
      console.log('开始上传文件 index=' + index)
      if (index <= len - 1) {
        getBase64(imageList[index].originFileObj, base64Url => {
          data.fileUrl = base64Url
        })
        const formData = new FormData()
        console.log(data.form.id)
        formData.append('file', imageList[index].originFileObj)
        imageUpload(formData).then(res => {
          if (res.code === 10000) {
            data.fileImgCodeLoading--
            data.fileImgCodeSuccess++
            data.form.fileId = res.data.id
            data.form.fileUrl = null
            // data.fileUrl = res.data.previewUrl
            save()
            if (index === len - 1) {
              setTimeout(() => {
                emit('uploadPic')
                emit('inspectionLoadData')
                emit('injuryPage')
                data.imageList = []
                data.editable = false
                message.info('批量上传完成')
                data.handleChangeStatus = false
                data.uploadShow = false
              }, 1000)
            } else {
              updateBatch(++index, len, imageList)
            }
          } else {
            message.info('图片过大，需要请缩小图片后上传')
            data.fileImgCodeError++
            data.fileImgCodeLoading--
            data.fileImgCodeErrorArry.push(index)
          }
        })
      } else {
        return false
      }
    }

    const edit = () => {
      data.editable = true
    }
    const save = () => {
      inspectUpload(props.info, data.form).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          data.editable = false
        }
      })
    }

    const onCancel = () => {
      data.editable = true
    }

    const handleCancel = () => {
      data.preview = false
    }
    const onUploadModal = () => {
      if (data.fileImgCodeLoading === 0) {
        data.uploadShow = false
      } else {
        data.uploadShow = true
        message.info('图片还没有全部上传完成，请不要退出')
      }
    }

    return {
      imageRef,
      ...toRefs(data),
      handleCancel,
      handlePreview,
      beforeUpload,
      handleChange,
      edit,
      save,
      onCancel,
      onUploadModal,
      submitError
    }
  },
  components: {
    PlusOutlined
    // EditOutlined
  }
}
</script>
<style lang="less" scoped>
:deep(.ant-upload) {
  &-drag {
    .ant-upload {
      padding: 0;
    }
  }
}
:deep(.ant-col > div) {
  height: 300px;
  font-size: 13px;
}

:deep(.avatar-uploader) {
  .ant-upload {
    height: 200px;
  }
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.upload-image {
  position: relative;
  height: 200px;

  &-info {
    &::before {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 0;
      transition: all 0.3s;
      content: ' ';
    }
  }

  &:hover {
    .upload-image-actions,
    .upload-image-info::before {
      opacity: 1;
    }
  }

  &-actions {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    white-space: nowrap;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.3s;
  }
}
.upload-list-inline :deep(.ant-upload-list-item) {
  float: left;
  width: 400px;

  margin-right: 8px;
}
.upload-list-inline :deep(.ant-upload-list-item) {
  float: left;
  width: 200px;
  margin-right: 8px;
}
.upload-list-inline :deep(.ant-upload-animate-enter) {
  animation-name: uploadAnimateInlineIn;
}
.upload-list-inline :deep(.ant-upload-animate-leave) {
  animation-name: uploadAnimateInlineOut;
}
.d {
  display: flex;
}
.col-b {
  width: 80%;
}
</style>
