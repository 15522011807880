<style lang="less" scoped>
</style>
<template>
  <div>
    <a-modal v-model:visible="modalIMGVisible" title="验车信息" width="85%" height="60vh" @cancel="onmodal(false)" destroyOnClose :footer=null>
      <a-card title="常规照片" hoverable :style="{ marginTop: '24px' }" :bodyStyle="{padding:0}">
        <a-row :gutter="24">
          <a-col :sm="24" :md="6" :xl="4" v-for="item in carValidateInfos" :key="item.id">
            <ValidateInfo :info="item" @change="imageChange" order=1 />
          </a-col>
        </a-row>
      </a-card>
      <a-card title="车伤/其他照片" hoverable :style="{ marginTop: '24px' }" :bodyStyle="{padding:0}">
        <div v-show="loading" style="display:flex;justify-content: center;margin:10px 0">
          <a-spin size="large" tip="加载中..." />
        </div>
        <a-row v-show="!loading" :gutter="24">
          <a-col :sm="24" :md="6" :xl="4" v-for="item in carInjuryInfos" :key="item.id">
            <ValidateInfo :info="item" @change="imageChange" order=1 />
          </a-col>
        </a-row>
        <a-pagination show-size-changer v-model:current="pagination.current" :pageSizeOptions="pagination.pageSizeOptions" v-model:pageSize="pagination.pageSize" :total="pagination.total" @change="handleTableChange" @showSizeChange="handleTableChange" />
        <a-row :gutter="24">
          <a-col :sm="24" :md="6" :xl="24">
            <VInfos :info="carid" @change="imageChange" order=1 @uploadPic="injuryPage" />
          </a-col>
        </a-row>
      </a-card>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent, reactive, toRefs } from 'vue'
import ValidateInfo from './components/ValidateInfo'
import VInfos from './components/ValidateInfos'
import { carPicsGroup } from '@/api/global'
export default defineComponent({
  setup () {
    const state = reactive({
      carValidateInfos: [],
      carInjuryInfos: [],
      carVInfos: [],
      carid: '',
      loading: false,
      modalIMGVisible: false,
      pagination: {
        current: 1,
        pageSize: 5,
        pageSizeOptions: ['5', '6', '10'],
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      }

    })
    const imageChange = file => {
    }

    const onmodal = (parameter) => {
      state.carValidateInfos = []
      state.carInjuryInfos = []
      state.carVInfos = []
      // state.pagination = {
      //   current: 1,
      //   pageSize: 10,
      //   total: 10
      // }
      // if (parameter === true) {
      //   setTimeout(function () {
      //     console.log('123312')
      //     state.modalIMGVisible = parameter
      //   }, 1000)
      // } else {
      state.modalIMGVisible = parameter
      // }
    }
    const oncarid = (parameter) => {
      state.carid = parameter
      loadData()
      injuryPage()
    }
    const loadData = async () => {
      state.loading = true
      carPicsGroup(state.carid, {
        injury: 0
      }).then(res => {
        if (res.code === 10000) {
          state.carValidateInfos = res.data.records
        }
        state.loading = false
      })
    }
    const injuryPage = () => {
      state.loading = true
      carPicsGroup(state.carid, {
        injury: 1,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.carInjuryInfos = res.data.records
          state.pagination.current = res.data.current
          state.pagination.pageSize = res.data.size
          state.pagination.total = res.data.total
        }
        state.loading = false
      })
    }
    const save = () => {
      state.carVInfos.push({
        id: '',
        fileId: '',
        fileUrl: null,
        orderVehicleId: state.carid,
        inspectType: {
          label: '车伤',
          value: 1
        },
        fileRemark: ''
      })
    }
    const saveList = () => {
      state.carValidateInfos.push({
        id: '',
        fileId: '',
        fileUrl: null,
        orderVehicleId: state.carid,
        inspectType: {
          label: '车伤压缩包',
          value: 100
        },
        fileRemark: ''
      })
    }
    const handleTableChange = (current, pageSize) => {
      state.pagination.current = current
      state.pagination.pageSize = pageSize
      injuryPage()
    }
    return {
      loadData,
      ...toRefs(state),
      oncarid,
      onmodal,
      save,
      injuryPage,
      saveList,
      handleTableChange,
      imageChange
    }
  },
  components: {
    ValidateInfo,
    VInfos
  }
})
</script>
